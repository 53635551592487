document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        if (cover) {
            setTimeout(function() {
                cover.remove();
                window.animsInit();
                document.documentElement.classList.add('is-ready');
                
                window.carousels();
                
                // Detect hash
                
                if (window.location.hash) {
                    console.log(window.location.hash);
                }
                                
                document.getElementsByClassName('js-count').length > 0 ? window.countAnim() : false;
                window.location.hash ? window.runScroll( '#'+document.getElementById(window.location.hash.substring(1)).id ) : false;

            }, 250);  
        }
    };
    
    window.addEventListener('load', init);

}, false);